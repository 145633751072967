export function useAccessibility() {
  function applyChartAccessibility() {
    const chart = document.querySelector('.chartjs-render-monitor')
    if (chart) {
      chart.setAttribute('role', 'img')
      chart.ariaLabel = 'Chart'
    }
  }

  return { applyChartAccessibility }
}
