export const ChartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6
        },
        gridLines: {
          display: true
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: false
        }
      }
    ]
  },
  legend: {
    display: true,
    position: 'bottom'
  },
  tooltips: {
    enabled: true,
    mode: 'point'
  },
  responsive: true,
  maintainAspectRatio: false
}
export const PieChartOptions = {
  legend: {
    display: true,
    position: 'bottom'
  },
  tooltips: {
    enabled: true,
    mode: 'point',
    callbacks: {
      label: function (tooltipItem, data) {
        return `${data.labels[tooltipItem.index]} \n ${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}`
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
}
